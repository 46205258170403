
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiFactoryLists, apiDetails, apiModeledit } from "@/api/shop";
import AddPrice from "@/components/goods/add-price.vue";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: {
    MaterialSelect,
    AddPrice,
  },
})
export default class ModelEdit extends Vue {
  $refs!: { form: any };
  /** S Data **/
  mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色
  pager: any;
  searchObj: any;
  // 表
  lists: Array<object> = [];
  activeName = "basic";
  // 表单数据
  form: any = {
    title: "",
    en_title: "",
    image: "",
    is_show: 1,
    price: "",
    sort: "",
    cost_price: "",
    factory_id: "",
    factory_price: "",
    spec_value_list: [
      {
        id: "",
        image: "",
        sell_price: 0,
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
        heigh: "",
        width: "",
        lenght: "",
      },
    ],
  };

  // 表单校验
  rules: object = {
    title: [{ required: true, message: "请输入名称", trigger: "blur" }],
    // price: [{ required: true, message: "请输入价格", trigger: "blur" }],
  };

  // 必传字段与对应的选项卡名字
  requireFields = [
    {
      fields: ["code", "name", "category_id", "goods_image"],
      activeName: "basic",
    },
    {
      fields: [
        "spec_value_list[0].stock",
        "spec_value_list[0].factory_price",
        "spec_value_list[0].cost_price",
        "spec_value_list[0].length",
        "spec_value_list[0].width",
        "spec_value_list[0].high",
      ],
      activeName: "price",
    },
    {
      fields: ["express_money", "express_template_id", "delivery_content"],
      activeName: "logistics",
    },
  ];

  // 规格绑定的数据
  specData: any = {
    spec_type: 1,
    spec_value: [],
    spec_value_list: [],
    specs_single: {},
  };

  factoryList: any[] = [];

  /** E Data **/

  /** S Methods **/
  // 提交表单
  onSubmit() {
    console.log(11);
    this.$refs.form.validate((valid: boolean, object: any): any => {
      if (valid) {
        apiModeledit(this.form).then((res) => {
          this.$router.push({
            name: "model_list",
            params: {
              pager: this.pager as any,
              searchObj: this.searchObj as any,
            },
          });
        });
      } else {
        const fieldsitem = this.requireFields.find((item) => {
          for (const value of item.fields) {
            if (object[value]) {
              this.$message.error(object[value][0].message);
              return true;
            }
          }
        });
        fieldsitem && (this.activeName = fieldsitem.activeName);
        return false;
      }
    });
  }

  goBack() {
    this.$router.push({
      name: "model_list",
      params: {
        pager: this.pager as any,
        searchObj: this.searchObj as any,
      },
    });
  }

  // 初始化表单数据
  async initFormDataForNoticeEdit(): Promise<void> {
    const res = await apiDetails({
      id: this.form.id,
    });
    this.initData(res);
    Object.keys(res).map((item) => {
      this.$set(this.form, item, res[item]);
    });
  }

  @Watch("specData", { deep: true })
  specDataChange(val: any) {
    this.form.spec_type = val.spec_type;
    if (val.spec_type == 1) {
      this.form.spec_value_list = [val.specs_single];
      this.form.spec_value = [];
      return;
    }
    this.form.spec_value_list = val.spec_value_list;
    this.form.spec_value = val.spec_value;
  }

  // 初始化规格数据
  initData(data: any = {}) {
    const spec_value = [
      {
        has_image: false,
        id: "",
        name: "",
        spec_list: [
          {
            id: "",
            value: "",
            image: "",
          },
        ],
      },
    ];
    const spec_value_list = [
      {
        id: "",
        image: "",
        sell_price: "",
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
      },
    ];

    const specData: any = {
      spec_value: data.spec_value || spec_value,
      spec_value_list: data.spec_value_list || spec_value_list,
      spec_type: data.spec_type || 1,
      specs_single: spec_value_list[0],
    };
    specData.spec_value.forEach((item: any) => {
      item.has_image = false;
      item.spec_list.forEach((sitem: any) => {
        sitem.image = "";
      });
    });

    if (data.spec_type == 1) {
      specData.spec_value = spec_value;
      specData.specs_single = data.spec_value_list[0];
    }
    Object.assign(this.specData, specData);
    // this.loading = false
  }

  // 工厂管理列表
  getFactoryLists() {
    apiFactoryLists({}).then((res) => {
      this.factoryList = res.lists;
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;

    if (query.mode) this.mode = query.mode;
    this.pager = this.$route.params.pager as any;
    this.searchObj = this.$route.params.searchObj as any;
    if (this.mode === PageMode.EDIT) {
      this.form.id = query.id * 1;
      this.initFormDataForNoticeEdit();
    }
    this.getFactoryLists();
    this.initData();
  }

  /** E Life Cycle **/
}
